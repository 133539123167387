<template>
  <div class="installment clearfix">
    <div class="installment-header">
      <div class="flex">
        <el-radio-group v-model="radio">
          <el-radio :disabled="!detailInfo.orderInfo.isPayStages" label="FK">分期付款</el-radio>
          <el-radio :disabled="!detailInfo.orderInfo.isDeliveryStages" label="JH">分期交货</el-radio>
        </el-radio-group>
        <el-popover placement="bottom-start" trigger="hover">
          <img style="width:900px" :src="getImgName" alt="" />
          <el-button slot="reference" class="btn" type="primary" size="mini">流程查询</el-button>
        </el-popover>
      </div>
      <div v-if="radio === 'JH'">
        <span>是否交货：</span>
        <el-select v-model="deliveryStatus" placeholder="请选择" @change="getList" clearable>
          <el-option v-for="item in options" :key="item.value" :label="item.label" :value="item.value"> </el-option>
        </el-select>
      </div>
      <div v-if="radio === 'FK'">
        <span>是否付款：</span>
        <el-select v-model="deliveryStatus" placeholder="请选择" @change="getList" clearable>
          <el-option v-for="item in options" :key="item.value" :label="item.label" :value="item.value"> </el-option>
        </el-select>
      </div>
      <div v-if="radio === 'FK'">
        <span>付款方式：</span>
        <el-select v-model="payMode" placeholder="请选择" @change="getList" clearable>
          <el-option v-for="item in optionsMode" :key="item.value" :label="item.label" :value="item.value"> </el-option>
        </el-select>
      </div>
    </div>
    <!-- 分期付款 -->
    <div v-if="radio === 'FK'">
      <div v-if="tableData.length">
        <div class="installment-main">
          <el-table :data="tableData" border style="width: 100%">
            <el-table-column align="center" prop="termIndexStr" label="分期数"> </el-table-column>
            <el-table-column align="center" prop="rate" label="金额比例(%)"> </el-table-column>
            <el-table-column align="center" label="付款时间">
              <template slot-scope="scope">
                {{ $moment(scope.row.date).format('YYYY.MM.DD') }}
              </template>
            </el-table-column>
            <el-table-column align="center" label="付款方式">
              <template slot-scope="scope">
                <span>{{ scope.row.payMode == 'YHZZ' ? '银行转账' : scope.row.payMode == 'CDHP' ? '商业承兑转账' : '银行承兑转账' }}</span>
              </template>
            </el-table-column>
            <el-table-column align="center" label="是否付款">
              <template slot-scope="scope">
                {{ scope.row.isFinish === 'Y' ? '是' : '否' }}
              </template>
            </el-table-column>
          </el-table>
        </div>
        <el-pagination class="fr" :background="true" layout="prev, pager, next ,jumper" @current-change="pageChange" :total="total" :page-size="5" />
      </div>
      <div v-else style="height: 300px;display: flex;align-items: center;justify-content: center;">
        <div style="display: flex;flex-direction: column;align-items: center;justify-content: center;">
          <img src="@/assets/empty.png" />
          <span style="margin-top:20px">暂无搜索内容请重新搜索</span>
        </div>
      </div>
    </div>
    <!-- 分期交货 -->
    <div v-if="radio === 'JH'">
      <div v-if="tableData.length">
        <div class="installment-main">
          <el-table :data="tableData" border style="width: 100%">
            <el-table-column align="center" prop="termIndexStr" label="分期数"> </el-table-column>
            <el-table-column align="center" prop="rate" label="交货比例(%)"> </el-table-column>
            <el-table-column align="center" label="交货时间">
              <template slot-scope="scope">
                {{ $moment(scope.row.date).format('YYYY.MM.DD') }}
              </template>
            </el-table-column>
            <el-table-column align="center" label="是否交货">
              <template slot-scope="scope">
                {{ scope.row.isFinish === 'Y' ? '是' : '否' }}
              </template>
            </el-table-column>
          </el-table>
        </div>
        <el-pagination class="fr" :background="true" layout="prev, pager, next ,jumper" @current-change="pageChange" :total="total" :page-size="5" />
      </div>
      <div v-else style="height: 300px;display: flex;align-items: center;justify-content: center;">
        <div style="display: flex;flex-direction: column;align-items: center;justify-content: center;">
          <img src="@/assets/empty.png" />
          <span style="margin-top:20px">暂无搜索内容请重新搜索</span>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import {getInstallmentData} from '../../api';
export default {
  name: 'Installment',
  props: {
    detailInfo: {
      type: Object
    }
  },
  data() {
    return {
      radio: '',
      isPay: '',
      payMode: '',
      options: [
        {
          value: 'Y',
          label: '是'
        },
        {
          value: 'N',
          label: '否'
        }
      ],
      optionsMode: [
        {
          value: 'CDHP',
          label: '承兑汇票'
        },
        {
          value: 'YHZZ',
          label: '银行转账'
        }
      ],
      hk_one_pay: require('@/assets/hk_one_pay.png'),
      hk_pay: require('@/assets/hk_pay.png'),
      hk_repeatedly_pay: require('@/assets/hk_repeatedly_pay.png'),
      kh_one_pay: require('@/assets/kh_one_pay.png'),
      kh_pay: require('@/assets/kh_pay.png'),
      kh_repeatedly_pay: require('@/assets/kh_repeatedly_pay.png'),
      deliveryStatus: '',
      tableData: [],
      total: 0,
      //当前页码数
      pageNum: 1,
      //每一页的条数
      pageSize: 5
    };
  },
  watch: {
    radio: function(val) {
      this.getList();
    }
  },
  updated() {
    // this.getList();
  },
  computed: {
    getImgName() {
      let imgUrl = '';
      // 卡片展开时显示分期付款或者分期发货
      this.radio = this.detailInfo.orderInfo.isPayStages ? 'FK' : this.detailInfo.orderInfo.isDeliveryStages ? 'JH' : '';
      if (this.detailInfo.orderInfo.orderProcess === 'HK') {
        // 如果是多次交货
        if (this.detailInfo.orderInfo.isDeliveryStages) {
          this.detailInfo.orderInfo.isPayStages ? (imgUrl = this.hk_pay) : (imgUrl = this.hk_one_pay);
        }
        // 如果是多次付款
        if (this.detailInfo.orderInfo.isPayStages) {
          this.detailInfo.orderInfo.isDeliveryStages ? (imgUrl = this.hk_pay) : (imgUrl = this.hk_repeatedly_pay);
        }
      }
      if (this.detailInfo.orderInfo.orderProcess === 'KH') {
        // 如果是多次交货
        if (this.detailInfo.orderInfo.isDeliveryStages) {
          this.detailInfo.orderInfo.isPayStages ? (imgUrl = this.kh_pay) : (imgUrl = this.kh_one_pay);
        }
        // 如果是多次付款
        if (this.detailInfo.orderInfo.isPayStages) {
          this.detailInfo.orderInfo.isDeliveryStages ? (imgUrl = this.kh_pay) : (imgUrl = this.kh_repeatedly_pay);
        }
      }
      return imgUrl;
    }
  },
  methods: {
    async getList() {
      let res = await getInstallmentData({
        orderId: this.$route.query.orderid,
        orderType: this.$route.query.orderType,
        type: this.radio,
        pageSize: this.pageSize,
        pageNum: this.pageNum,
        isFinish: this.deliveryStatus,
        payMode: this.payMode
      });
      if (res.errno === 0) {
        this.tableData = res.data.list;
        this.total = res.data.total;
      }
    },
    pageChange(val) {
      this.pageNum = val;
      this.getList();
    }
  }
};
</script>
<style lang="less" scoped>
.installment {
  padding: 40px;
  background-color: #fff;
  .btn {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 70px;
    height: 25px;
    margin: -5px 0 0 65px;
  }
  &-header {
    display: flex;
    justify-content: space-between;
    align-items: center;
  }
  &-main {
    padding: 40px 0 55px 0;
  }
}
</style>
